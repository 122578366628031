<template>
  <tr v-show="!item.isEdit" @click="openEditLine(index)">
    <td class="pl-6">
      <div class="col">
        <strong>
          {{ item.name }}
        </strong>
        {{ item.description }}
      </div>
    </td>
    <td class="text-center">
      {{ item.stock_in_hand }}
      <span v-if="item.description">
        {{ item.description }}
      </span>
    </td>
    <td class="text-center">{{ item.quantity }}</td>
    <td class="text-center">{{ item.prev_return_qty }}</td>
    <td class="text-center">{{ item.return_qty }}</td>
    <td class="text-center">{{ item.rate }}</td>
    <td class="text-center">{{ item.amount }}</td>
    <td class="text-center">{{ item.discount_percent }}%</td>
    <td class="text-center">{{ item.discount_amount }}</td>
    <td class="text-center">{{ amountAfterDiscount }}</td>
    <td class="text-center">{{ item.vat }}%</td>
    <td class="text-center">{{ item.vat_amount }}</td>
    <td class="text-center">{{ totalAmount }}</td>
    <td class="text-center">{{item.return_amount}}</td>
  </tr>

  <tr v-show="item.isEdit" :class="item.isEdit && 'tr-box-shadow'">
    <td>
      <div class="col">
        <strong>
          {{ item.name }}
        </strong>
        {{ item.description }}
      </div>
    </td>
    <td>
      <div class="td-inline">
        {{ item.stock_in_hand }}
        <span v-if="item.description">
          {{ item.description }}
        </span>
      </div>
    </td>
    <td class="text-center">
      {{ item.quantity }}
    </td>
    <td class="text-center">
      {{ item.prev_return_qty }}
    </td>
    <td>
      <div class="td-inline">
        <input
          v-model.trim="item.return_qty"
          type="number"
          class="form-control"
          placeholder="Return Qty"
          :min="0"
        >
      </div>
    </td>
    <td class="text-center">
      {{item.rate}}
    </td>
    <td class="text-center">
      {{amount}}
    </td>
    <td class="text-center">
      {{item.discount_percent}}
    </td>
    <td class="text-center">
      {{item.discount_amount}}
    </td>
    <td class="text-center">
      {{amountAfterDiscount}}
    </td>
    <td class="text-center">
      <div class="td-inline">
        {{item.vat}}
      </div>
    </td>
    <td class="text-center">
      {{item.vat_amount}}
    </td>
    <td class="text-center">
      {{totalAmount}}
    </td>
    <td class="text-center">
      {{item.return_amount}}
    </td>
  </tr>

</template>
<script setup>
import {computed, inject, onMounted, ref, watch} from "vue";
import handlePurchase from "@/services/modules/purchase";
import {useRoute} from "vue-router";

const props = defineProps(['item', 'index', 'onOpenEditItem', 'onDeleteItem', 'vatRate'])
const emit = defineEmits(['onOpenEditItem', 'onDeleteItem']);
const $route = useRoute();
const showError = inject('showError')
const formData = inject('formData')
const companyId = $route.params.companyId
const companyQuery = `?company_id=${companyId}`;
const openEditLine = (i) => {
  emit('onOpenEditItem', i)
}
const amount = computed(() => props.item.amount = (props.item.rate * props.item.quantity).toFixed(4))

const discountPercent = computed(() => props.item.discount_percent);

const amountAfterDiscount = computed(() => {
  const discountAmount = props.item.discount_amount === '' ? 0 : parseFloat(props.item.discount_amount).toFixed(4)
  if(discountAmount === 0) {
    return parseFloat(amount.value).toFixed(4);
  }
  return (amount.value - discountAmount).toFixed(4)
})

const vatAmount = computed(() => {

  if(props.item.vat === 0) {
    return 0
  }

  if(props.item.vat) {
    return (props.item.vat/100 * amountAfterDiscount.value).toFixed(4)
  }
  return (props.item.vat/100 * amountAfterDiscount.value).toFixed(4);
})

const productId = ref(null)

const totalAmount = computed(() => {
  if(props.item.vat_amount === 0) {
    return props.item.total_amount = parseFloat(amountAfterDiscount.value).toFixed(4)
  }
  return props.item.total_amount = (parseFloat(amountAfterDiscount.value) + parseFloat(props.item.vat_amount)).toFixed(4)
})

const returnQty = computed(() => props.item.return_qty ?? 0);

const getAmount = (index) => {
  if (props.item[index] && props.item[index] > 0) {
    const amount = (props.item[index]/props.item.quantity) * props.item.return_qty;
    props.item[`return_${index}`] = amount;
    return amount;
  }
  return 0;
}

watch(returnQty, (newValue, oldValue) => {
  if (newValue && newValue > 0) {
    const discountAmount = getAmount('discount_amount');
    const vatAmount = getAmount('vat_amount');
    const quantity = props.item.quantity;
    const prevReturnQty = props.item.prev_return_qty;
    const remainingQty = (quantity - prevReturnQty);
    if(newValue > remainingQty) {
        showError("Return quantity can not be more than order quantity")
        props.item.return_qty = remainingQty;
        return;
    }

    props.item.return_amount = ((newValue * props.item.rate) - discountAmount + vatAmount).toFixed(4)
    return;
  }

  props.item.return_amount = 0;
})

watch(vatAmount, (newValue, oldValue) => {
  if(! isNaN(oldValue) || !route.params.invoiceId){
    props.item.vat_amount  = newValue;
  }
})

watch(discountPercent, (value) => {
  let discount = 0;
  value = parseFloat(value);
  if(value && value > 0 && amount.value > 0) {
    discount = amount.value * (value/100);
  }
  props.item.discount_percent = value;
  props.item.discount_amount = discount.toFixed(4);
})

onMounted(() => {
  if (props.item.stock_register_id) {
    productId.value = props.item.stock_register_id
  }
})

</script>