<template>
  <div class="card">

    <div class="bg-blue-light p-1">
      <TitleButton
        :showBtn="true"
        :showAddNew="false"
        title="Pharmacy Bill Return"
        @onClickCloseButton="goToList"
      />
    </div>

    <div class="my-2 px-2">
      <TableHeaderData
        :formData="formData"
        :tableItems="tableItems"
        :isDisabled="true"
      />
    </div>

    <div class="col-12 px-2">
      <PartialPharmacyBillReturnTable
        :tableItems="tableItems"
        @addNewItem="addNewItem"
        :salesAccountHeads="salesAccountHeads"
        :vatRate="vatRate"
        :is-hide-add-btn="true"
      />
    </div>
    <div class="row px-2">
      <div class="col-md-6">
        <div class="mb-1">
          <label class="form-label" for="description">Ledger Memo</label>
          <vField
            as="textarea"
            name="description"
            type="number"
            class="form-control"
            v-model="formData.description"
          />
        </div>
      </div>
      <div class="col-md-5 ml-auto mt-auto mb-1">
        <div class="d-flex flex-wrap gap-1 gy-2">
          <button :disabled="loader" @click="handleSubmit()" class="btn btn-primary">Submit Return</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import {inject, onMounted, provide, reactive, ref} from 'vue';
import TitleButton from '@/components/atom/TitleButton';
import TableHeaderData
    from '@/components/molecule/company/hospital/billing/bill-manager/TableHeaderHospitalBillAdd.vue';
import {useRoute, useRouter} from 'vue-router';
import {generateUUID} from '@/services/utils/global';
import handlePurchase from '@/services/modules/purchase';
import {vatRate} from '@/data/inventory.js';
import handleCompany from "@/services/modules/company";
import hospitalPdfPrinterHelper from '@/services/utils/hospitalPdfPrinterHelper';
import handleHospitalBilling from "@/services/modules/hospital/billing";
import PartialPharmacyBillReturnTable
    from "@/components/molecule/company/hospital/billing/bill-manager/PartialPharmacyBillReturnTable.vue";
import useDate from "@/services/utils/day";
import salesReturnHelper from "@/services/utils/pdf/salesReturnHelper";

const {generatePdf} = hospitalPdfPrinterHelper();
const {fetchSalesBill, ...rest} = handlePurchase();
const {fetchCompanyInfo} = handleCompany();
const {returnBillings} = handleHospitalBilling();
const { fetchAndGeneratePdf } = salesReturnHelper();

const $route = useRoute();
const $router = useRouter();
const dateHelper = useDate();

const companyId = $route.params.companyId;
const loader = ref(true);
const salesAccountHeads = ref([]);
const totals = ref({});
const invoiceRes = ref({});
const company = ref({});

let formData = ref({
  company_id: companyId,
  contact_profile_id: null,
  account_head_id: null,
  ipd_register_id: null,
  vat_payable_account_head_id: null,
  business_id: null,
  project_id: null,
  cost_centre_id: null,
  location_id: null,
  mop_account_head_id: null,
  discount_head_id: null,
  service_resource_id: null,
  receipt_reference: null,
  adjustment_amount: 0,
  receipt_description: "Pharmacy bill payment",
  payment: null,
  money_receipt_no: '',
  status: 'active',
  bill_number: '',
  date: '',
  has_item_detail: true,
  description: '',
  order_number: null,
  sales_person_id: null,
  commission_percent: null,
  lc_number: null,
  item_details: []
});

provide('formData', formData);
provide('totals', totals);
const showSuccess = inject('showSuccess');
const showError = inject('showError');

let tableItems = reactive([]);

const addNewItem = () => {

  tableItems.push({
    id: generateUUID(),
    product_id: null,
    name: '',
    description: '',
    quantity: 1,
    rate: 0,
    discount_percent: 0,
    vat: 15,
    sub_total: 0,
    discount_amount: 0,
    vat_amount: 0,
    total: 0,
    isEdit: false,
  });

}

const goToList = () => {
  const {redirect_route, ...routeQuery} = $route.query
  const routeName = redirect_route ? redirect_route : "bill-manager"
  $router.push({
    name: routeName,
    params: $route.params,
    query: {
      ...routeQuery
    }
  })
}

const handleSubmit = () => {
  loader.value = true

  formData.value.item_details = tableItems;
  let copyFormData = JSON.parse(JSON.stringify(formData.value))
  let isAllItemsReturned = !formData.value.item_details.find(item => parseInt(item.return_qty ?? '0') !== (item.quantity - item.prev_return_qty));
  copyFormData.item_details = formData.value.item_details.map(item => {
    return {
      product_id: item.product_id,
      stock_register_id: item.stock_register_id,
      quantity: item.return_qty,
      rate: item.rate,
      vat: item.vat,
      discount_amount: item.return_discount_amount,
      discount_percent: item.discount_percent,
      vat_amount: item.return_vat_amount,
      amount: item.return_amount,
      total_amount: item.total_amount,
      sale_general_id: item.id,
      stock_in_hand: item.stock_in_hand,
      prev_return_qty: item.prev_return_qty,
      description: item.description,
    }
  })
  copyFormData.sale_type = $route.query.sale_type ?? "pharmacy_invoice"
  copyFormData.return_type = isAllItemsReturned ? "returned" : "partially_returned"
  copyFormData.adjustment_amount = formData.value.return_roundup_discount
  returnBillings(copyFormData).then(res => {
    if (!res.status) {
      return showError(res.message)
    }
    showSuccess(res.message)
    fetchAndGeneratePdf(company.value, res.data.id, 'pharmacy_invoice')
    goToList();
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loader.value = false
  })
}

onMounted(async () => {
  const companyQuery = `?company_id=${companyId}`;
  const salesBillQuery = `${companyQuery}&return_data=1`
  const payableRes = rest.fetchAccountReceivable(companyQuery);
  const vatPayableRes = rest.fetchVatPayable(companyQuery);
  const fetchCompany = fetchCompanyInfo(companyId)
  const getSalesBill = fetchSalesBill($route.params.invoiceId, salesBillQuery)
  const salesAccountHeadsRes = rest.getAccountHeadBySlag("sales_accounts", companyQuery)

  Promise.all([
    payableRes.then(res => {
      if (res.data) formData.value.account_head_id = res.data[0].id;
    }),
    vatPayableRes.then(res => {
      if (res.data) formData.value.vat_payable_account_head_id = res.data[0].id;
    }),
    fetchCompany.then(res => {
      if (res.data) company.value = res.data;
    }),
    salesAccountHeadsRes.then(res => {
      if (res.data) salesAccountHeads.value = res.data
    }),
    getSalesBill.then(res => {
      if (res.data) {
        const {id, date, account_head, company, contact_profile, discount_ledger, general, receipt_generals, stocks} = res.data;
        formData.value = res.data;
        formData.value.invoice_date = date;
        formData.value.date = dateHelper.currentDate();
        formData.value.sale_master_id = id;
        formData.value.adjustment_amount = res.data.adjustment_amount ?? 0;
        formData.value.receipt_type = "pharmacy_invoice_return";
        const items = general.map(td => {
          return {
            id: td.id,
            product_id: td.product_id,
            stock_register_id: td.stock_register_id,
            name: td.product.name,
            option_name: td.product.name,
            description: td.description,
            quantity: td.quantity,
            rate: td.rate,
            discount: td.discount,
            vat: td.vat,
            sub_total: td.sub_total,
            discount_amount: td.discount_amount,
            discount_percent: td.discount_percent,
            vat_amount: td.vat_amount,
            stock_in_hand: td.stock_in_hand,
            total: td.total,
            isEdit: false,
            product: {name: td.product.name},
            prev_return_qty: td.prev_return_qty,
            amount: td.amount,
            total_amount: td.total_amount
          }
        });
        tableItems.push(...items)
        if (receipt_generals && receipt_generals[0]) {
          formData.value.money_receipt_no = receipt_generals[0].receipt_master.voucher_no
          formData.value.mop_account_head_id = receipt_generals[0].receipt_master.account_head_id
          formData.value.receipt_master_id = res.data.receipt_generals[0].receipt_master.id;
          formData.value.receipt_reference = receipt_generals[0].receipt_master.receipt_reference
        }
        if (discount_ledger) {
          formData.value.discount_head_id = discount_ledger.account_head_id;
          formData.value.adjustment_amount = discount_ledger.debit;
        }
      }
    })
  ])
    .then(() => {
      loader.value = false
    })
    .catch(() => {
      loader.value = false
    })
})

</script>

<style scoped>
address {
  margin-bottom: 0 !important;
}
</style>
